import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, Grid2, type Grid2Props, IconButton, styled, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useLayout } from '@/components/page/layout/mod';

interface IFormFieldsContainerProps extends Grid2Props {
    readonly containerLabel?: string;
    readonly collapsible?: boolean | 'expanded';
}

export const FormFieldsContainer: React.FC<IFormFieldsContainerProps> = ({
    children,
    collapsible = false,
    containerLabel,
    ...rest
}) => {
    const { isDesktop } = useLayout();
    const initialExpanded = collapsible === 'expanded' || !collapsible;
    const [expanded, setExpanded] = useState(initialExpanded);

    const handleToggle = () => setExpanded(!expanded);

    return (
        <Box sx={{ mb: collapsible === false || expanded ? 3 : 0 }}>
            {containerLabel && (
                <TitleBar>
                    <Typography variant="h5">{containerLabel}</Typography>
                    {collapsible !== false && (
                        <IconButton size="small" onClick={handleToggle}>
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                </TitleBar>
            )}
            <Collapse in={expanded}>
                <Grid2 container columnSpacing={isDesktop ? 2 : 1} spacing={1} size={12} {...rest}>
                    {children}
                </Grid2>
            </Collapse>
        </Box>
    );
};

const TitleBar = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(1),
}));
