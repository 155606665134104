import { yupResolver } from '@hookform/resolvers/yup';
import { Grid2, Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { usePreloadAccountInformation } from '../api/preload-account-information-referral';
import { useRegistration } from '../api/registration';

import { duplicateCustomerEmailValidation } from '@/api/user/endpoints';
import { ActionButton } from '@/components/elements/action/action-button';
import { Heading } from '@/components/elements/heading';
import { InteractiveText } from '@/components/elements/text/interactive-text';
import { CheckField } from '@/components/form/fields/check-field';
import { TextField } from '@/components/form/fields/text-field';
import { AuthInputs } from '@/components/form-inputs/auth-inputs';
import { ContactDataInputs } from '@/components/form-inputs/contact-data-inputs';
import { OrganizationInputs } from '@/components/form-inputs/organization-inputs';
import { useAppCtx } from '@/core/app-ctx/mod';
import { trackRegisteredCustomerGroup } from '@/core/google/utils/ga-utils';
import { useSnack } from '@/core/snack/mod';
import {
    defaultRegistrationFormValues,
    preloadRegistrationFormValues,
    registrationSchema,
    type RegistrationFormValues,
} from '@/features/auth';
import { Dialog } from '@/features/ui';
import { useDisclosure } from '@/hooks/useDisclosure';
import { RegistrationRoute } from '@/main/routes/registration';
import { SanitizedInnerHTML } from '@/utils/SanitizedInnerHTML';

export const RegistrationForm: React.FC = () => {
    const { t } = useTranslation();
    const register = useRegistration();
    const navigate = useNavigate();
    const snack = useSnack();
    const { integration } = useAppCtx();
    const { token } = RegistrationRoute.useSearch();
    const { data: preloadedData } = usePreloadAccountInformation(token);

    const formMethods = useForm<RegistrationFormValues>({
        defaultValues: defaultRegistrationFormValues(),
        values: preloadRegistrationFormValues(preloadedData, token),
        resolver: yupResolver(registrationSchema),
    });

    const data = formMethods.watch();

    useEffect(() => {
        console.log(data, defaultRegistrationFormValues());
    }, [data]);

    const onSubmit = async (values: RegistrationFormValues) => {
        const email = formMethods.getValues('email');

        try {
            await duplicateCustomerEmailValidation(email);
        } catch {
            formMethods.setError(
                'email',
                {
                    type: 'duplicate-email-validation',
                    message: t('validations:email.duplicate_email_customer'),
                },
                { shouldFocus: true },
            );
            return;
        }

        try {
            const { id } = await register.mutateAsync(values);
            trackRegisteredCustomerGroup(t(`customer_groups.id.${values.customerGroupId}`, { options: 'en' }));

            if (id != null) await navigate({ to: '/registration/confirmation', search: { id: id.toString() } });
        } catch {
            snack.push(t('validations:general.error'), 'error');
        }
    };

    const agbDialog = useDisclosure();
    return (
        <>
            {token && (
                <>
                    <Heading
                        disableGutterBottom
                        color="primary"
                        title={t('account.referral.info_headline_registration')}
                    />
                    <SanitizedInnerHTML html={t('account.referral.info_text_registration')} />
                    <Typography variant="caption">{t('account.referral.valid_until')}</Typography>
                </>
            )}
            <FormProvider {...formMethods}>
                <form noValidate onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Grid2 container sx={{ marginTop: token ? 5 : 0 }} spacing={0}>
                        <Grid2 size={12}>
                            <AuthInputs />
                        </Grid2>
                        <Grid2 size={12}>
                            <OrganizationInputs withOrganizationRegistration />
                        </Grid2>
                        <Grid2 size={12}>
                            <ContactDataInputs withoutEmail optional />
                        </Grid2>
                        <Grid2 size={{ sm: 6, xs: 12 }}>
                            <CheckField
                                label={
                                    <Typography variant="body2">
                                        {t('account.core_data.subscribe_to_newsletter')}
                                    </Typography>
                                }
                                name="subscribedToNewsletter"
                            />
                        </Grid2>
                        <Grid2 size={{ sm: 6, xs: 12 }}>
                            <CheckField
                                label={
                                    <Typography variant="body2">
                                        <Trans
                                            i18nKey="registration.agb"
                                            tOptions={{ companyName: integration?.companyName ?? 'busfinder.com' }}
                                            components={{
                                                button1: <InteractiveText color="primary" onClick={agbDialog.open} />,
                                            }}
                                        />
                                    </Typography>
                                }
                                name="agb"
                            />
                        </Grid2>
                        <Grid2 size={12} mt={1}>
                            <ActionButton type="submit">{t('account.register')}</ActionButton>
                        </Grid2>
                    </Grid2>
                    <TextField name="referralToken" type="hidden" />
                </form>
            </FormProvider>
            <Dialog
                {...agbDialog}
                variant="info"
                maxWidth="lg"
                title={t('agb.modal_title_busfinder')}
                description={
                    <SanitizedInnerHTML html={t('page_agb_content', { interpolation: { escapeValue: false } })} />
                }
            />
        </>
    );
};
