import { Grid2, type Grid2Props } from '@mui/material';
import { isNumber } from 'lodash';
import React, { Children } from 'react';

interface FormFieldRowProps extends Grid2Props {
    readonly insertEmptyColumn?: boolean | number;
}

export const FormFieldRow: React.FC<FormFieldRowProps> = ({ children, insertEmptyColumn }) => {
    const emptyColumnLength = isNumber(insertEmptyColumn) ? insertEmptyColumn : insertEmptyColumn ? 1 : 0;

    const itemCount = Children.count(children) + emptyColumnLength;
    const smWidth = 12 / itemCount;

    return (
        <>
            {Children.map(children, child => (
                <Grid2
                    size={{
                        xs: 12,
                        sm: smWidth,
                    }}>
                    {child}
                </Grid2>
            ))}
            {insertEmptyColumn && (
                <Grid2
                    display={{ xs: 'none', sm: 'block' }}
                    size={{
                        sm: smWidth,
                    }}
                />
            )}
        </>
    );
};
